<template>
  <div v-loading="dataLoading" class="home-page">
    <div class="app-container">
      <!-- Layout -->
      <el-row :gutter="20" class="row-bg" justify="center">
        <!-- Content -->
        <el-col :sm="24" :md="20">
          <el-form ref="profileForm" :model="profileForm" :rules="profileRules">
            <el-form-item>
              <el-row type="flex" justify="center">
                <el-col :span="5" align="center">
                  <label id="form-upload" class="form-upload" :class="{preview: urlPreview}">
                    <div v-if="urlPreview">
                      <img :src="urlPreview" alt="avatar preview">
                      <div class="middle">
                        <i class="el-icon-edit" style="margin-right:5px" />
                        <input type="file" @change="handleFileUpload">
                      </div>
                    </div>
                    <div v-else>
                      <i class="el-icon-plus" for="uploadInput" />
                      <input type="file" @change="handleFileUpload">
                    </div>
                  </label>
                </el-col>

              </el-row>
            </el-form-item>

            <!-- Row 1 -->
            <el-row :gutter="20" justify="center">
              <el-col :md="13">
                <div class="brand-color mb-5">メール </div>
                <el-form-item prop="email">
                  <el-input
                    v-model="profileForm.email"
                    size="small"
                    tabindex="1"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <!-- Row 2 -->

            <!-- Row 3 -->
            <el-row :gutter="20" justify="center">
              <el-col :md="13">
                <div class="brand-color mb-5">ユーザー名</div>
                <el-form-item prop="name">
                  <el-input
                    v-model="profileForm.name"
                    size="small"
                    tabindex="1"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <!-- Row 4 -->

            <el-row :gutter="10" class="mt-50 mb-50 text-center">
              <el-col>
                <el-button
                  type="primary"
                  style="width:30%;margin-bottom:30px;"
                  @click="handleEditProfile"
                >プロファイル編集</el-button>
              </el-col>
              <el-col>
                <el-button
                  type="primary"
                  style="width:30%;margin-bottom:30px;"
                  @click="handleChangePassword"
                >パスワードの変更</el-button>
              </el-col>
            </el-row>
          </el-form></el-col></el-row></div>
  </div>
</template>

<script>
import { getProfileInfo, editProfile } from '@/api/profile'
import { uploadImage } from '@/api/upload'

export default {
  name: 'EditProfile',
  data() {
    return {
      imagePreview: false,
      dataLoading: false,
      profileForm: {
        email: '',
        name: '',
        avatar: ''
      },
      profileRules: {
        email: [
          { required: true, type: 'email', message: '「メールアドレス」を正しく入力してください', trigger: ['blur', 'change'] }
        ],
        name: [
          { required: true, trigger: 'blur', message: '「ユーザー名」を入力してください' }
        ]
      },
      imageFile: null,
      urlPreview: null
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.dataLoading = true
      getProfileInfo(this.$route.params.id).then((response) => {
        if (response.data) {
          this.profileForm.email = response.data.email
          this.profileForm.name = response.data.name
          this.profileForm.avatar = response.data.avatar
          this.urlPreview = this.profileForm.avatar ? process.env.VUE_APP_BASE_STORAGE + '/' + this.profileForm.avatar : null
        }

        // Just to simulate the time of the request
        setTimeout(() => {
          this.dataLoading = false
        }, 0.1 * 1000)
      })
    },

    createData() {
      this.dataLoading = true
      editProfile(this.profileForm).then((response) => {
        this.$notify({
          title: 'Success',
          message: 'データは正常に作成されました',
          type: 'success',
          duration: 2000
        })

        // Just to simulate the time of the request
        setTimeout(() => {
          this.dataLoading = false
        }, 0.1 * 1000)
      }).catch((response) => {
        this.dataLoading = false
      })
    },

    upload(imageFile, funcHandle) {
      const formData = new FormData()
      formData.append('file', imageFile)
      formData.append('folder_name', 'avatar')
      formData.append('type', 'image')
      formData.append('old_file', this.profileForm.avatar)
      uploadImage(formData).then((response) => {
        this.profileForm.avatar = response.data.url
        funcHandle()
      })
    },

    handleEditProfile() {
      this.$refs.profileForm.validate((valid) => {
        if (valid) {
          if (this.imageFile) {
            this.upload(this.imageFile, this.createData)
          } else {
            this.createData()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    // handle Image input
    handleFileUpload(e) {
      const file = e.target.files[0]
      this.imageFile = file
      this.urlPreview = URL.createObjectURL(file)
    },

    handleChangePassword() {
      this.$router.push('/change-password')
    }
  }
}
</script>

<style lang="scss">
.btn-custom{
   border-radius: 7px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 1),
    rgba(187, 183, 178, 1)
  );
  color: #495057;
}
.btn-custom.pressed {
  background-image: linear-gradient(
    rgba(184, 227, 207, 1),
    rgb(14, 92, 238)
  );
}
.form-upload {
  background: #909399;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  line-height: 100px;
  vertical-align: top;
    input {
      display: none !important;
    }
    input {
      display: none !important;
    }
    img {
      position: relative;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      line-height: 100px;
      object-fit: cover;
    }
    .middle {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
}
</style>

